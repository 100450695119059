import { HStack } from "../../chakra-ui/stack";
import { VisuallyHidden } from "../../chakra-ui/visually-hidden";
import { Flex } from "../../chakra-ui/flex";
import { DigitContainer } from "./DigitContainer";
import { NumberDivider } from "./NumberDivider";
import { COMMON_DATE_TIME_FORMATTER_OPTIONS } from "../../../hooks/project/date-time/useDateFormatter";
import { DateTime } from "../date-time/date-time";
import type { BoxProps } from "@chakra-ui/react";
import type { UseTimeDifferencesResult } from "../../../hooks/project/date-time/useTimeDifferences";

type CountDownVariant = "dd-hh-mm-ss" | "mm-dd-hh-mm-ss" | "yy-mm-dd-hh-mm-ss";

type FlipVariantComponent = JSX.Element | null;

type labels = {
    yearLabel?: string;
    monthLabel?: string;
    dayLabel?: string;
    remainingDayLabel?: string;
    hourLabel?: string;
    minuteLabel?: string;
    secondLabel?: string;
};

type FlipVariants = Record<CountDownVariant, FlipVariantComponent>;

type CountdownProps = BoxProps &
    UseTimeDifferencesResult & {
        variant?: CountDownVariant;
        labels: labels;
        date: string;
        ariaLabel?: string;
    };

const Countdown = ({
    completed,
    days,
    hours,
    minutes,
    seconds,
    remainingDays,
    months,
    years,
    labels,
    variant = "dd-hh-mm-ss",
    date,
    ariaLabel,
    totalSeconds,
    ...props
}: CountdownProps) => {
    if (completed) return null;

    // Clock variants
    const flipVariants: FlipVariants = {
        "dd-hh-mm-ss": (
            <>
                <DigitContainer number={days} variant="days" title={labels.dayLabel!} />
                <NumberDivider />
            </>
        ),
        "mm-dd-hh-mm-ss": (
            <>
                <DigitContainer number={months} variant="months" title={labels.monthLabel!} />
                <NumberDivider />
                <DigitContainer number={remainingDays} variant="days" title={labels.dayLabel!} />
                <NumberDivider />
            </>
        ),
        "yy-mm-dd-hh-mm-ss": (
            <>
                <DigitContainer number={years} variant="years" title={labels.yearLabel!} />
                <NumberDivider />
                <DigitContainer number={months} variant="months" title={labels.monthLabel!} />
                <NumberDivider />
                <DigitContainer number={remainingDays} variant="days" title={labels.dayLabel!} />
                <NumberDivider />
            </>
        ),
    };

    return (
        <Flex className="countdown" {...props} pb={4}>
            <HStack gap={0} alignItems="flex-start">
                {flipVariants[variant]}
                <DigitContainer number={hours} variant="hours" title={labels.hourLabel!} />
                <NumberDivider />
                <DigitContainer number={minutes} variant="mins" title={labels.minuteLabel!} />
                <NumberDivider />
                <DigitContainer number={seconds} variant="secs" title={labels.secondLabel!} />
            </HStack>
            <VisuallyHidden>
                <p>
                    {ariaLabel}{" "}
                    <DateTime dateFormatterOptions={COMMON_DATE_TIME_FORMATTER_OPTIONS.DateTime}>
                        {date}
                    </DateTime>
                </p>
            </VisuallyHidden>
        </Flex>
    );
};

export { Countdown };
export type { CountdownProps };
